import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-evaluateur/aap.service';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { ProjetService } from '@services-evaluateur/projet.service';
import { UtilisateurService } from '@services-evaluateur/utilisateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { AvisValidators } from '@shared-evaluateur/utils/validators/avis-validator';
import {
  Aap,
  EnumNotationEtape,
  EnumNotationTypeNote,
  Notation,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'pxl-projet-instruction-notation-creation',
  templateUrl: './projet-instruction-notation-creation.component.html',
  styleUrls: ['./projet-instruction-notation-creation.component.scss'],
})
export class ProjetInstructionNotationCreationComponent extends SubscriptionDestroyerComponent implements OnInit {
  pageIsReady = false;
  projet: Projet = new Projet();
  instructionForm: UntypedFormGroup;
  aap: Aap | null = new Aap();
  projetId: string;

  matricule: string;
  notationId: string;
  notation: Notation = new Notation();
  utilisateur: Utilisateur;

  typeNotation = 'Instruction';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public aapService: AapService,
    private formBuilder: UntypedFormBuilder,
    public projetService: ProjetService,
    public sharedFunction: SharedFunction,
    private userService: UtilisateurService,
    public evaluateurService: EvaluateurService,
    private showToastrService: ShowToastrService
  ) {
    super();
  }

  ilab = false;
  isInstructionEnCours = false;
  readOnly = false;

  ngOnInit(): void {
    this.userService
      .getUtilisateurObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(response => {
        this.utilisateur = response;
      });
    this.getProjet();
    this.createForms();
    this.loadNotation();
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.projetId;
    this.projetService
      .getProjetObservable()
      .pipe(
        concatMap(responseProjet => {
          if (responseProjet) {
            this.projet = responseProjet;
            return this.aapService.loadAapSubject();
          }
        })
      )
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: responseAap => {
          if (responseAap) {
            this.aap = responseAap.body;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * Intialisation du formulaire
   * */
  createForms(): void {
    const notationType = this.getNotationType();

    this.instructionForm = this.formBuilder.group({
      avis: [null, [Validators.required, AvisValidators.notationSur20Validator(notationType)]],
      propositionGrandPrix: [null, Validators.required],
      commentaire: [null],
      recommandationIntentionCandidat: [null],
    });
  }

  /*
   * Récupère les infos du projet
   * */
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.instructionForm.dirty;
  }

  loadNotation(): void {
    this.notationId = this.route.snapshot.params.notationId;
    this.matricule = this.route.snapshot.params.matricule;

    if (this.matricule) {
      this.notation.evaluateurMatricule = this.matricule;
    } else if (this.notationId) {
      this.evaluateurService
        .getNotationsById(this.notationId)
        .pipe(this.takeUntilDestroyed())
        .subscribe(response => {
          if (response) {
            this.notation = response.body;
            const formNotation = this.formatNoteProjet(response.body);
            this.instructionForm.patchValue(formNotation);
            this.notation.evaluateurMatricule = this.notation.evaluateur?.matricule;
          }
        });
    } else {
      this.userService
        .getUtilisateurObservable()
        .pipe(this.takeUntilDestroyed())
        .subscribe(response => {
          this.notation.evaluateurMatricule = response.matricule;
        });
    }
  }

  onGoToNotationEval(): void {
    this.instructionForm.reset();
    this.instructionForm.markAsPristine();
    this.onGoBack();
  }

  /*
   * Modifie les informations du projet rentrées dans le formulaire
   * */
  modifyNotation(): void {
    if (this.instructionForm.valid) {
      Object.assign(this.notation, this.instructionForm.value);
      this.notation.projetId = this.projetId;
      this.notation.etape = EnumNotationEtape.INSTRUCTION;
      this.notation = this.formatNoteProjet(this.notation, true);
      this.evaluateurService
        .saveNotation(this.notation)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (response: any) => {
            if (response) {
              this.notation = response.body;
              this.showToastrService.success('Notation enregistrée avec succès');
              this.onGoToNotationEval();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    } else {
      this.instructionForm.markAllAsTouched();
    }
  }

  onGoBack(): void {
    this.router.navigate([(this.notationId || this.matricule ? '../' : '') + '../projet-instruction'], { relativeTo: this.route });
  }

  formatNoteProjet(notation: Notation, savingNotation?: boolean): Notation {
    if (notation?.avis) {
      let note = notation.avis.toString();
      note = savingNotation ? note.replace(',', '.') : note.replace('.', ',');
      notation.avis = note;
    }
    return notation;
  }

  getNotationType(): EnumNotationTypeNote {
    return this.sharedFunction.getNotationType(this.aap, this.typeNotation);
  }
}
