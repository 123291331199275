import { enumKeys } from '../../utils/enum-utils';

export enum EnumNotationCreatorType {
  EVALUATEUR = 'EVALUATEUR',
  INSTRUCTEUR = 'INSTRUCTEUR',
  OWN = 'OWN',
}

export namespace EnumNotationCreatorType {
  export function all(): EnumNotationCreatorType[] {
    return enumKeys(EnumNotationCreatorType) as unknown as EnumNotationCreatorType[];
  }
}
