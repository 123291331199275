import { Component, Input, OnInit } from '@angular/core';
import { Aap, CritereReferentiel, EnumNotationEtape, EnumNotationTypeNote, IntervenantRoles, Projet, Utilisateur } from '@shared-ui';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetSyntheseNotationBaseComponent } from '../projet-synthese-notation-base.component';
import { SyntheseNotationCardHelperService } from './synthese-notation-card.helper.service';

@Component({
  selector: 'pxl-synthese-notation-card',
  templateUrl: './synthese-notation-card.component.html',
  styleUrls: ['./synthese-notation-card.component.scss'],
})
export class SyntheseNotationCardComponent extends ProjetSyntheseNotationBaseComponent implements OnInit {
  @Input() aap: Aap = new Aap();
  @Input() projet: Projet = new Projet();
  @Input() isProjetPhaseEvaluation = false;
  @Input() isProjetPhaseAudition = false;
  @Input() utilisateur: Utilisateur = new Utilisateur();
  @Input() stepNotation: EnumNotationEtape;
  @Input() isUserReadOnly: boolean;
  @Input() criteresEvaluations: CritereReferentiel[] = [];
  @Input() canUserWrite: boolean;
  @Input() typeNotation: string;

  isLongPointsForts = false;
  isLongPointsFaibles = false;
  readMorePointsForts = false;
  readMorePointsFaibles = false;
  readMoreCommentaire = false;
  isLongCommentaire = false;

  isLongAvis = false;
  readMoreAvis = false;
  isLongMotifDeRefus = false;
  readMoreMotifDeRefus = false;
  isLongSuggestionDeReorientation = false;
  readMoreSuggestionDeReorientation = false;

  tagNote = '';

  constructor(public sharedFunction: SharedFunction, private route: ActivatedRoute, private router: Router) {
    super(sharedFunction);
  }

  ngOnInit(): void {
    this.isLongPointsForts = this.isTextLong(this.notation?.avisExpert?.pointsForts?.length);
    this.isLongPointsFaibles = this.isTextLong(this.notation?.avisExpert?.pointsFaibles?.length);
    this.isLongCommentaire = this.isTextLong(this.notation?.commentairePasNote?.length);

    this.isLongAvis = this.isTextLong(this.notation?.avisExpert?.avis?.length);
    this.isLongMotifDeRefus = this.isTextLong(this.notation?.avisExpert?.motifDeRefus?.length);
    this.isLongSuggestionDeReorientation = this.isTextLong(this.notation?.avisExpert?.suggestionDeReorientation?.length);

    this.updateTagForNote();
  }

  showMorePropositionsExperts(): void {
    let notationId = '';
    if (this.notation?.id) {
      notationId = this.notation.id;
    }

    this.router.navigate(['../projet-notation-phase-opportunite/' + notationId], {
      relativeTo: this.route,
      queryParams: { openExpertAccordion: true },
    });
  }

  afficherPlusPointsForts(): void {
    this.readMorePointsForts = !this.readMorePointsForts;
  }

  afficherPlusPointsFaibles(): void {
    this.readMorePointsFaibles = !this.readMorePointsFaibles;
  }

  getNotationType(): EnumNotationTypeNote {
    const typeNotation = EnumNotationEtape.toString(this.stepNotation);
    return this.sharedFunction.getNotationType(this.aap, typeNotation);
  }

  afficherPlusCommentaire(): void {
    this.readMoreCommentaire = !this.readMoreCommentaire;
  }

  afficherPlusAvis(): void {
    this.readMoreAvis = !this.readMoreAvis;
  }

  afficherPlusMotifDeRefus(): void {
    this.readMoreMotifDeRefus = !this.readMoreMotifDeRefus;
  }

  afficherPlusSuggestionDeReorientation(): void {
    this.readMoreSuggestionDeReorientation = !this.readMoreSuggestionDeReorientation;
  }

  isNotationEvaluateurExterne(): boolean {
    return (
      this.notation.evaluateur?.utilisateurRole === IntervenantRoles.EXPERT_INDEPENDANT ||
      this.notation.evaluateur?.utilisateurRole === IntervenantRoles.EXPERT_MINISTERIEL
    );
  }

  updateTagForNote() {
    this.tagNote = SyntheseNotationCardHelperService.getTagNote(this.notation, this.criteresEvaluations);
  }

  protected readonly IntervenantRoles = IntervenantRoles;
  protected readonly EnumNotationTypeNote = EnumNotationTypeNote;
}
