import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-evaluateur/environment';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { DemandeInscription, EnumNotationEtape, Evaluateur, Notation } from '@shared-ui';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EvaluateurService {
  evaluateursUrl = environment.apiUrl + 'evaluateurs';
  evaluateurUrlAdmin = environment.apiAdminUrl + 'evaluateurs';
  private evaluateurSubject: Observable<HttpResponse<Evaluateur>>;

  notationsUrl = environment.apiUrl + 'notations';

  demandeInscriptionUrl: string;

  constructor(private httpClient: HttpClient, public sharedFunction: SharedFunction) {
    this.demandeInscriptionUrl = environment.apiUrl + 'demandes-inscriptions';
  }

  affectEvaluateurs(role: string, projectsId: string[], emails: string[], notationEtapes: string[]): Observable<HttpResponse<any>> {
    const body = JSON.stringify({ role, projectsId, emails, notationEtapes });
    return this.httpClient.put<HttpResponse<any>>(this.evaluateursUrl + '/affecterProjets', body, { observe: 'response' });
  }

  affectEvaluateursLectureSeule(role: string, projectsId: string[], emails: string[]): Observable<HttpResponse<any>> {
    const body = JSON.stringify({ role, projectsId, emails });
    return this.httpClient.put<HttpResponse<any>>(this.evaluateurUrlAdmin + '/affectation-consultation', body, { observe: 'response' });
  }

  affectJuryNational(role: string, projectsId: string[], emails: string[]): Observable<HttpResponse<any>> {
    const body = JSON.stringify({ role, projectsId, emails });
    return this.httpClient.put<HttpResponse<any>>(this.evaluateurUrlAdmin + '/affectation-expert', body, { observe: 'response' });
  }

  desaffectEvaluateurs(idProjets: string[], emailsEvaluateurs: string[]): Observable<HttpResponse<string[]>> {
    const body = JSON.stringify({ idProjets, emailsEvaluateurs });
    return this.httpClient.put<string[]>(this.evaluateurUrlAdmin + '/desaffectation', body, { observe: 'response' });
  }

  getEvaluateur(email: string): Observable<HttpResponse<Evaluateur>> {
    if (this.evaluateurSubject == null) {
      const evaluateur = this.getEvaluateurInfos(email).pipe(shareReplay({ refCount: true }));
      this.setEvaluateurObservableForCGU(evaluateur);
      this.evaluateurSubject = evaluateur;
    }
    return this.getEvaluateurObservable();
  }

  public setEvaluateurObservableForCGU(user: Observable<HttpResponse<Evaluateur>>): void {
    this.evaluateurSubject = user;
  }

  getEvaluateurObservable(): Observable<HttpResponse<Evaluateur>> {
    return this.evaluateurSubject || new Observable();
  }

  getEvaluateurInfos(email: string): Observable<HttpResponse<Evaluateur>> {
    return this.httpClient.get<Evaluateur>(this.evaluateursUrl + '/' + email, {
      observe: 'response',
    });
  }

  getNotationsByProjetId(projetId: string, notationEtape: EnumNotationEtape): Observable<HttpResponse<Notation[]>> {
    return this.httpClient.get<Notation[]>(environment.apiUrl + 'notations' + '?projetId=' + projetId + '&notationEtape=' + notationEtape, {
      observe: 'response',
    });
  }

  getNotationsById(notationId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(this.notationsUrl + '/' + notationId, {
      observe: 'response',
    });
  }

  getDemandeInscriptionById(demandeInscriptionId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(this.demandeInscriptionUrl + '/' + demandeInscriptionId, {
      observe: 'response',
    });
  }

  validerDemandeInscription(demandeInscriptionId: string, acceptation: string): Observable<HttpResponse<DemandeInscription>> {
    return this.httpClient.post<DemandeInscription>(
      this.demandeInscriptionUrl + '/' + demandeInscriptionId + '/validation?acceptation=' + acceptation,
      {},
      { observe: 'response' }
    );
  }

  checkRadicalMail(mail: string): Observable<HttpResponse<any>> {
    const radical = mail.split('@').pop();
    return this.httpClient.get<any>(environment.apiUrl + 'organismes?radicalmail=@' + radical, {
      observe: 'response',
    });
  }

  saveNotation(notation: Notation): Observable<HttpResponse<any>> {
    if (notation.id) {
      return this.httpClient.put<HttpResponse<any>>(this.notationsUrl + '/' + notation.id, notation, { observe: 'response' });
    } else {
      return this.httpClient.post<HttpResponse<any>>(this.notationsUrl, notation, { observe: 'response' });
    }
  }

  saveStatutNotation(notation: Notation): Observable<HttpResponse<any>> {
    return this.httpClient.put<HttpResponse<any>>(
      environment.apiUrl + 'notations/' + notation.id + '/statut-prise-en-compte',
      notation.statutPriseEnCompte,
      { observe: 'response' }
    );
  }

  getEvaluateursByProjetId(projetId: string, notationEtape: EnumNotationEtape): Observable<HttpResponse<Evaluateur[]>> {
    return this.httpClient.get<any>(environment.apiUrl + 'evaluateurs' + '?projetId=' + projetId + '&notationEtape=' + notationEtape, {
      observe: 'response',
    });
  }

  reactivationCompte(): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(this.evaluateursUrl + '/demandes-reactivation-compte', {}, { observe: 'response' });
  }

  renewAccess(demandeAccessId: string): Observable<HttpResponse<object>> {
    return this.httpClient.put(this.evaluateursUrl + '/reactivation-compte?demandeReactivationCompteId=' + demandeAccessId, null, {
      observe: 'response',
    });
  }

  getStatutInvitationCurrentEvaluateur(): Observable<HttpResponse<{ active: boolean }>> {
    return this.httpClient.get<{ active: boolean }>(`${environment.apiUrl}evaluateurs/invitation/statut`, {
      observe: 'response',
    });
  }
}
