import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjetService } from '@services-evaluateur/projet.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';
import { ComiteProjet, ProjetLight, ProjetTableElement, ShowToastrService } from '@shared-ui';

@Component({
  selector: 'pxl-tableau-projet',
  templateUrl: './tableau-projet.component.html',
  styleUrls: ['./tableau-projet.component.scss'],
})
export class TableauProjetComponent {
  readonly THEMATIQUE_MAX_LENGTH = 35;
  dataSource: MatTableDataSource<ProjetTableElement> = new MatTableDataSource<ProjetTableElement>([]);

  @Input() displayedColumns!: string[];
  @Input() autoSelectAll = false;
  @Input() selection = new SelectionModel<ProjetTableElement>(true, []);
  @Output() selectionChange = new EventEmitter<number>();
  @Input()
  set data(ds: ProjetTableElement[]) {
    this.loadSavedSorting();
    this.dataSource.data = ds;
    if (this.sort && this.dataSource) {
      this.selection.clear();
      if (this.autoSelectAll) {
        this.selectAll();
      }
      this.dataSource.sort = this.sort;
      this.dataSource.data.map(element => {
        element.budget = Number(element.budget);
        element.montantAideDemande = Number(element.montantAideDemande);
      });
    }
  }
  @Output() dataChange = new EventEmitter<number>();
  @Output() elementClicked = new EventEmitter<ProjetLight | ComiteProjet>();
  @Input() savedSort?: Sort;
  @Output() sortChange = new EventEmitter<Sort>();

  @ViewChild(MatSort) sort!: MatSort;
  constructor(public sharedFunction: SharedFunction, private projetService: ProjetService, private showToastrService: ShowToastrService) {
    this.dataSource.sortingDataAccessor = this.getSortingData();
  }

  private getSortingData() {
    return (dataItem: ProjetTableElement, sortHeaderId: string) => {
      const value = dataItem[sortHeaderId as keyof ProjetTableElement];
      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      if (value instanceof Date) {
        return value.getDate();
      }
      if (value instanceof Array) {
        return value.join('');
      }
      if(typeof value === 'boolean') {
        return value.toString();
      }

      return value;
    };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected, otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selectAll();
    }
  }

  private selectAll() {
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  downloadArchiveUnitaire(event: Event, projetId: string, docProject: boolean): void {
    event.stopPropagation();
    this.projetService.downloadArchiveUnitaire(projetId, docProject).subscribe({
      next: () => {
        this.showToastrService.success(
          "La demande de génération de l'archive est en cours, vous devriez recevoir un mail d'ici quelques minutes." +
            '</br></br>' +
            'Si aucun des projets sélectionnés ne comporte des documents, aucun mail ne sera envoyé.',
          '',
          { enableHtml: true }
        );
      },
      error: () => {
        this.showToastrService.checkCodeError({ code: 'erreur.telechargement.document' });
      },
    });
  }

  onElementClicked(projet: ProjetLight | ComiteProjet): void {
    localStorage.setItem('aap-project-list-sorting', JSON.stringify({ active: this.sort.active, direction: this.sort.direction }));
    this.elementClicked.emit(projet);
  }

  private loadSavedSorting() {
    if (this.savedSort && this.sort) {
      this.sort.active = this.savedSort.active;
      this.sort.direction = this.savedSort.direction;
    }
  }

  onSortChange(event: Sort) {
    this.sortChange.emit(event);
  }
}
