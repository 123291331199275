import { FilterRsSiretInterface } from '../interfaces/filterData.interface';
import { SearchObjectInterface } from '../interfaces/searchObject.interface';
import { RangeDate } from './rangeDate.model';

export class SearchObject implements SearchObjectInterface {
  idAAPs: string[] = [];
  acronymes: string[] = [];
  siretRaisonSociale: FilterRsSiretInterface[] = [];
  thematiques: string[] = [];
  datesReleves: string[] = [];
  etapes: string[] = [];
  statuts: string[] = [];
  taches: string[] = [];
  drdl: string[] = [];
  idComites: string[] = [];
  phasesDecision: string[] = [];
  comiteId: string;
  index = -1;
  limit = -1;
  inputAcronyme = '';
  inputSiretRS = '';
  affectedOnly = false;
  partenaireType: string[] = [];
  rangeDatesReleve: RangeDate;
  aapOrigineId: string;
  idPoles: string[] = [];
  etatsProjet: string[] = ['ACTIF'];
}
