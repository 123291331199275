import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  Aap,
  EnumActivite,
  EnumNotationEtape,
  EnumPermissionUtilisateur,
  EnumProjetEtape,
  EnumProjetStatut,
  Evaluateur,
  EvaluateurAffectation,
  EvaluateurNotation,
  IntervenantRoles,
  Notation,
  PermissionUtils,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EvaluateurService } from '@services-evaluateur/evaluateur.service';
import { SharedFunction } from '@shared-evaluateur/utils/sharedFunction';

@Component({
  selector: 'pxl-projet-notation-jury-national',
  templateUrl: './projet-notation-jury-national.component.html',
  styleUrls: ['./projet-notation-jury-national.component.scss'],
})
export class ProjetNotationJuryNationalComponent extends SubscriptionDestroyerComponent implements OnInit, OnChanges {
  @Input() aap!: Aap;
  @Input() projet!: Projet;
  @Input() user!: Utilisateur;

  readonly INSTRUCTION = EnumNotationEtape.INSTRUCTION;
  readonly expertJuryNational = IntervenantRoles.EXPERT_JURY_NATIONAL;
  readonly vicePresidentJuryNational = IntervenantRoles.VICE_PRESIDENT_JURY_NATIONAL;

  showMaNotation!: boolean;
  private projetId!: string;
  projetEvaluateurs!: Evaluateur[];
  notations: Notation[] = [];
  notationsUtilisateur: Notation[] = [];
  readOnly = false;
  notationsExpertJuryNational: Notation[] = [];
  isInstructionEnCours = false;
  isUserInterneBPI = false;
  expertsJuryNational!: EvaluateurAffectation[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private evaluateurService: EvaluateurService,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction
  ) {
    super();
  }

  ngOnInit(): void {
    this.projetId = this.route.snapshot.parent?.params.projetId;
    this.getNotations();
  }

  getNotations(): void {
    this.evaluateurService
      .getNotationsByProjetId(this.projetId, this.INSTRUCTION)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: responseNotations => {
          this.notations = responseNotations.body;
          this.notationsExpertJuryNational = responseNotations.body;
          this.canSeeMaNotation();
          this.notationsUtilisateur = this.notations.filter(notation => notation.evaluateur?.matricule === this.user.matricule);
          this.notations = this.notations.filter(
            notation =>
              notation.evaluateur?.matricule !== this.user.matricule &&
              notation.evaluateur.utilisateurRole !== this.expertJuryNational &&
              notation.evaluateur.utilisateurRole !== this.vicePresidentJuryNational
          );
          this.notationsExpertJuryNational = this.notationsExpertJuryNational.filter(
            notation =>
              notation.evaluateur?.matricule !== this.user.matricule &&
              (notation.evaluateur.utilisateurRole === this.expertJuryNational ||
                notation.evaluateur.utilisateurRole === this.vicePresidentJuryNational)
          );
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
        complete: () => {
          this.getProjetEvaluateurs();
        },
      });
  }

  getProjetEvaluateurs(): void {
    this.evaluateurService.getEvaluateursByProjetId(this.projetId, this.INSTRUCTION).subscribe({
      next: responseEvaluateurs => {
        this.projetEvaluateurs = responseEvaluateurs.body ? responseEvaluateurs.body : [];
        this.trierEvaluateurs();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  trierEvaluateurs(): void {
    this.projetEvaluateurs.forEach(evaluateur => {
      const evaluateurAffectation = evaluateur.listAffectationProjets.find(
        affectation => affectation.id === this.projetId && affectation.notationEtapes?.includes(this.INSTRUCTION)
      );

      if (evaluateurAffectation) {
        const notationEvaluateur: EvaluateurNotation = new EvaluateurNotation();
        const notationIncomplete: Notation = new Notation();

        notationEvaluateur.id = evaluateur.id;
        notationEvaluateur.email = evaluateur.email;
        notationEvaluateur.matricule = evaluateur.matricule;
        notationEvaluateur.nom = evaluateur.nom;
        notationEvaluateur.prenom = evaluateur.prenom;

        if (
          evaluateurAffectation.utilisateurRole === this.expertJuryNational &&
          !this.notationsExpertJuryNational.find(notation => notation.evaluateur.email === evaluateur.email) &&
          evaluateur.matricule !== this.user.matricule
        ) {
          notationEvaluateur.utilisateurRole = evaluateurAffectation.utilisateurRole;

          notationIncomplete.evaluateur = notationEvaluateur;
          this.notationsExpertJuryNational.push(notationIncomplete);
        }
      }
    });
  }

  setIsUserInterneBpi() {
    this.isUserInterneBPI = false;
  }

  canSeeMaNotation(): void {
    this.showMaNotation =
      this.sharedFunction.canUserWriteActivity(
        this.user,
        this.projet,
        EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE,
        EnumActivite.INSTRUCTION
      ) || PermissionUtils.hasPermissionOnProjet(this.user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ, this.projet);
    this.readOnly = !this.sharedFunction.canUserNoteProjectInstruction(this.user, this.projet);
  }

  setInstructionEnCours(): void {
    this.isInstructionEnCours =
      this.projet.statut === (EnumProjetStatut as any)[EnumProjetStatut.EN_COURS] &&
      this.sharedFunction.getProjectEtapeName(this.projet) === (EnumProjetEtape as any)[EnumProjetEtape.INSTRUCTION];
  }

  onGoToProjetInstruction(notation: Notation): void {
    let notationId = '';
    if (notation) {
      notationId = notation.id;
    }

    if (!((this.showMaNotation && !this.readOnly) || this.isUserInterneBPI)) {
      return;
    }

    const extras: NavigationExtras = { relativeTo: this.route };
    extras.state = { ilab: 'true', isInstructionEnCours: this.isInstructionEnCours, readOnly: this.readOnly };

    if (this.isInstructionEnCours) {
      this.router.navigate(['../projet-instruction-notation-creation/' + notationId], extras);
    } else if (notationId) {
      this.router.navigate(['../../projet-instruction-notation-creation/' + notationId], extras);
    } else if (notation?.evaluateur?.matricule) {
      this.router.navigate(['../../projet-instruction-notation-creation-admin/' + notation?.evaluateur?.matricule], extras);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.user && this.user) {
      this.setIsUserInterneBpi();
    }
    if (changes && changes.projet && this.projet) {
      this.expertsJuryNational = this.projet?.evaluateurs.filter(
        evaluateur =>
          evaluateur.utilisateurRole === this.expertJuryNational || evaluateur.utilisateurRole === this.vicePresidentJuryNational
      );
      this.setInstructionEnCours();
    }
  }
}
