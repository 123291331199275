<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="{{ savedSort?.active }}"
  matSortDirection="{{ savedSort?.direction }}"
  (matSortChange)="onSortChange($event)"
>
  <caption>
    Liste des projets
  </caption>
  <ng-container matColumnDef="select" class="">
    <th id="projet-select" mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </th>
    <td id="id" mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
      <mat-checkbox (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"> </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="aapNomProgramme" class="">
    <th id="projet-programme" mat-header-cell *matHeaderCellDef mat-sort-header>Programme</th>
    <td id="programme" mat-cell *matCellDef="let element">
      <span>{{ element.aapNomProgramme }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="aapCode" class="">
    <th id="projet-aap" mat-header-cell *matHeaderCellDef mat-sort-header>Appel à projet</th>
    <td id="aap" mat-cell *matCellDef="let element">
      <span>{{ element.aapCode }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="identifiantPXL" class="">
    <th id="projet-numero" mat-header-cell *matHeaderCellDef mat-sort-header>Numéro</th>
    <td id="structure_numero" mat-cell *matCellDef="let element">{{ element.identifiantPXL }}</td>
  </ng-container>
  <ng-container matColumnDef="acronyme" class="">
    <th id="projet-name" mat-header-cell *matHeaderCellDef mat-sort-header>Projet</th>
    <td id="nom" mat-cell *matCellDef="let element">
      <strong>{{ element.acronyme }}</strong>
    </td>
  </ng-container>
  <ng-container matColumnDef="structureCf" class="">
    <th id="projet-chefDeFile" mat-header-cell *matHeaderCellDef mat-sort-header>Chef de File</th>
    <td id="structure_pilote" mat-cell *matCellDef="let element">{{ element.structureCf }}</td>
  </ng-container>
  <ng-container matColumnDef="libelleThematique" class="">
    <th id="projet-thematique" mat-header-cell *matHeaderCellDef mat-sort-header>Thématique</th>
    <td
      id="thematique"
      mat-cell
      *matCellDef="let element"
      [matTooltipDisabled]="element.libelleThematique?.length <= THEMATIQUE_MAX_LENGTH"
      [matTooltip]="element.libelleThematique"
      [style.max-width.ch]="THEMATIQUE_MAX_LENGTH"
    >
      {{ element.libelleThematique }}
    </td>
  </ng-container>
  <ng-container matColumnDef="budget" class="">
    <th id="projet-budget" mat-header-cell *matHeaderCellDef mat-sort-header>Budget présenté</th>
    <td id="budget" mat-cell *matCellDef="let element">{{ element.budget ? (element.budget | number) : '-' }} €</td>
  </ng-container>
  <ng-container matColumnDef="aideDemande" class="">
    <th id="montant-aide-demande" mat-header-cell *matHeaderCellDef mat-sort-header>Montant de l'aide</th>
    <td id="aideDemande" mat-cell *matCellDef="let element">
      {{ element.montantAideDemande ? (element.montantAideDemande | number) : '-' }} €
    </td>
  </ng-container>
  <ng-container matColumnDef="relevePreDepot" class="">
    <th id="projet-dateReleve" mat-header-cell *matHeaderCellDef mat-sort-header>Relève pré-dépôt</th>
    <td id="relevePreDepot" mat-cell *matCellDef="let element">
      {{ element.relevePreDepot ? (element.relevePreDepot | date : 'dd/MM/yyyy') : '-' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="releveDepot" class="">
    <th id="projet-dateReleveDepot" mat-header-cell *matHeaderCellDef mat-sort-header>Relève dépôt</th>
    <td id="releveDepot" mat-cell *matCellDef="let element">
      {{ element.releveDepot ? (element.releveDepot | date : 'dd/MM/yyyy') : '-' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="etape" class="">
    <th id="projet-step" mat-header-cell *matHeaderCellDef mat-sort-header>Etape</th>
    <td id="etape" mat-cell *matCellDef="let element">{{ element.etape }}</td>
  </ng-container>
  <ng-container matColumnDef="statut" class="">
    <th id="projet-statut" mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
    <td id="statut" mat-cell *matCellDef="let element">
      <span class="table__status">{{ element.statut }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="tag" class="">
    <th id="projet-correction" mat-header-cell *matHeaderCellDef mat-sort-header>Correction</th>
    <td id="tag" mat-cell *matCellDef="let element">
      <span class="table__tag">{{ element.tag }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="dateEvaluation" class="">
    <th id="projet-evaluation-date" mat-header-cell *matHeaderCellDef mat-sort-header>Date d'évaluation</th>
    <td id="dateEvaluation" mat-cell *matCellDef="let element">
      {{ element.dateEvaluation ? (element.dateEvaluation | date : 'dd/MM/yyyy') : '-' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateAudition" class="">
    <th id="projet-audition-date" mat-header-cell *matHeaderCellDef mat-sort-header>Date d'audition</th>
    <td id="dateAudition" mat-cell *matCellDef="let element">
      {{ element.dateAudition ? (element.dateAudition | date : 'dd/MM/yyyy') : '-' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="partenaireType" class="">
    <th id="projet-type" mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td id="typeProjet" mat-cell *matCellDef="let element">
      {{ element.partenaireType }}
    </td>
  </ng-container>
  <ng-container matColumnDef="propositionComite" class="">
    <th id="projet-comite-proposition" mat-header-cell *matHeaderCellDef mat-sort-header>Proposition</th>
    <td id="comiteProposition" mat-cell *matCellDef="let element">
      {{ element.propositionComite }}
    </td>
  </ng-container>
  <ng-container matColumnDef="decisionComite" class="">
    <th id="projet-comite-validation-cpmo" mat-header-cell *matHeaderCellDef mat-sort-header>Décision validé CPMO</th>
    <td id="comiteValidationCPMO" mat-cell *matCellDef="let element">
      {{ element.decisionComite }}
    </td>
  </ng-container>
  <ng-container matColumnDef="dateDecisionEtat" class="">
    <th id="projet-comite-date-validation-cpmo" mat-header-cell *matHeaderCellDef mat-sort-header>Date de validation CPMO</th>
    <td id="comiteDateValidationCPMO" mat-cell *matCellDef="let element">
      {{ element.dateDecisionEtat ? (element.dateDecisionEtat | date : 'dd/MM/yyyy') : '-' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="aapCodeActuel" class="">
    <th id="projet-aap-actuel" mat-header-cell *matHeaderCellDef mat-sort-header>Appel à projet actuel</th>
    <td id="aapCodeActuel" mat-cell *matCellDef="let element">
      <span>{{ element.aapCode }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="dateReorientation" class="">
    <th id="projet-date-rerientation-date" mat-header-cell *matHeaderCellDef mat-sort-header>Date de réorientation</th>
    <td id="dateReorientation" mat-cell *matCellDef="let element">
      {{ element.dateReorientation ? (element.dateReorientation | date : 'dd/MM/yyyy') : '-' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="auteurReorientation" class="">
    <th id="projet-auteur-rerientation-date" mat-header-cell *matHeaderCellDef mat-sort-header>Auteur</th>
    <td id="auteurReorientation" mat-cell *matCellDef="let element">
      {{ element.auteurReorientation }}
    </td>
  </ng-container>
  <ng-container matColumnDef="archive" class="">
    <th id="projet-download" mat-header-cell *matHeaderCellDef>
      <!-- TODO Remove feature fliping archive V1 -->
      <div [libMyRemoveIfFeatureOff]="'archiveV1'">
        <p class="p_label_telechargement">Téléchargement</p>
      </div>
      <!-- TODO Remove feature fliping archive V1 -->
    </th>

    <td id="archive" mat-cell *matCellDef="let element">
      <!-- TODO Remove feature fliping archive V1 -->
      <ng-container *ngIf="sharedFunction.isEvaluateurInstructeurExterne(evaluateur)">
        <div class="div_boutton_telechargement">
          <button class="card__action-button" (click)="downloadArchiveUnitaire($event, element.id, false)">
            <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
          </button>
          <p class="p_boutton_telechargement" (click)="downloadArchiveUnitaire($event, element.id, false)"><u>Dossier complet</u></p>
        </div>
      </ng-container>
      <!-- TODO Remove feature fliping archive V1 -->

      <!-- TODO Remove feature fliping archive V1 -->
      <div class="div_boutton_telechargement" [libMyRemoveIfFeatureOff]="'archiveV1'">
        <button class="card__action-button" (click)="downloadArchiveUnitaire($event, element.id, true)">
          <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
        </button>
        <p class="p_boutton_telechargement" (click)="downloadArchiveUnitaire($event, element.id, true)"><u>Documents projet</u></p>
      </div>
      <!-- TODO Remove feature fliping archive V1 -->
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    class="table-row"
    *matRowDef="let row; columns: displayedColumns"
    (click)="onElementClicked(row)"
    style="cursor: pointer"
  ></tr>
</table>
<div class="no-result" *ngIf="dataSource?.data?.length === 0">
  <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
  <span id="large-span-result">Il n'y a rien ici...</span>
  <span id="small-span-result">0 projet répertorié.</span>
</div>
